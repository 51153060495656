<template>
  <div class="page-login-desktop">

    <Table
      label="لیست تراکنش ها"
      icon="mdi-format-list-bulleted"
      :table="table"
    >
      <template slot="header">
        <v-btn
          @click="filters.modal=true"
          elevation="0"
          large
          color="main"
          class="white--text"
        >
          جستجو
        </v-btn>
      </template>
      <tr
        v-for="(item,index) in table.items"
        :key="index"
      >
        <td class="text-center">{{ index + 1 }}</td>
        <td class="text-center">{{ item.username }}</td>
        <td class="text-center">{{ item.managermobile }}</td>
        <td class="text-center">{{ item.agencyname }}</td>
        <td
          class="text-center"
          style="width: 250px"
        >{{ item.customermobile }} ( {{ item.customername }} )</td>
        <td class="text-center">
          <v-chip
            color="primary"
            outlined
            small
            v-if="item.AccountType === 1"
          >
            مدیر
          </v-chip>
          <v-chip
            color="warning"
            outlined
            v-else
            small
          >
            مشاور
          </v-chip>
        </td>
        <td class="text-center">{{ item.subject }}</td>
        <td class="text-center">{{ item.refId }}</td>
        <td class="text-center">
          <v-chip
            color="success"
            text-color="white"
            small
            v-if="item.status"
          >
            پرداخت شده
          </v-chip>
          <v-chip
            color="error"
            text-color="white"
            small
            v-else
          >
            پرداخت نشده
          </v-chip>
        </td>
        <td class="text-center">{{ item.price }}</td>
        <td class="text-center">{{ item.created_at }}</td>
        <td class="text-center">
          <MessageButton @click="item.actions.replay" />
          <DeleteButton @click="item.actions.destroy" />
        </td>
      </tr>
    </Table>

    <Pagination
      v-model="filters.skip"
      :key="filters.skip"
      :totalItems="table.totalCount"
      :perPage="filters.take"
      @change="(page)=>{fetchItems({skip: page})}"
    />

    <NewMessage
      @onSubmit="fetchItems"
      :messageData="newMessage"
    />

    <v-dialog
      width="500"
      v-model="filters.modal"
    >
      <v-card>
        <v-card-title>
          <v-icon class="ml-2">
            mdi-magnify
          </v-icon>
          فیلتر های جستجو
        </v-card-title>

        <v-card-text>
          <v-row>
            <v-col :cols="6">
              <v-text-field
                v-model="filters.DateSelection1"
                hide-details
                dense
                outlined
                label="از تاریخ"
                class="start-date-picker"
              />
              <date-picker
                :max="filters.DateSelection2"
                auto-submit
                v-model="filters.DateSelection1"
                format="jYYYY/jMM/jDD"
                custom-input=".start-date-picker"
              />
            </v-col>
            <v-col :cols="6">
              <v-text-field
                v-model="filters.DateSelection2"
                hide-details
                dense
                outlined
                label="تا تاریخ"
                class="end-date-picker"
              />
              <date-picker
                auto-submit
                v-model="filters.DateSelection2"
                format="jYYYY/jMM/jDD"
                custom-input=".end-date-picker"
              />
            </v-col>
            <v-col :cols="12">
              <v-text-field
                hide-details
                outlined
                label="جستجو..."
                v-model="filters.Userfilter"
              />
            </v-col>
          </v-row>
        </v-card-text>

        <v-card-actions class="d-flex align-center justify-space-between">
          <v-btn
            large
            elevation="0"
            color="warning"
            @click="resetFilters"
          >
            ریست
          </v-btn>

          <v-btn
            class="white--text"
            large
            elevation="0"
            color="main"
            @click="fetchItems"
          >
            جستجو
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog
      v-model="deleteItem.modal"
      width="500"
    >
      <v-card>
        <v-card-title class="text-body-1 ">
          <v-icon class="ml-2">
            mdi-information-outline
          </v-icon>
          حذف
        </v-card-title>
        <v-card-text>
          آیا از حذف این پرداخت مطمئن هستید ؟
        </v-card-text>

        <v-card-actions class="d-flex align-center justify-space-between">
          <v-btn
            @click="deleteItem.modal=false"
            large
            text
            color="main"
          >
            لغو
          </v-btn>

          <v-btn
            :loading="deleteItem.loading"
            :disabled="deleteItem.loading"
            @click="destroyItem"
            elevation="0"
            large
            color="error"
          >
            حذف
          </v-btn>
        </v-card-actions>
      </v-card>

    </v-dialog>

  </div>
</template>

<script>
import VuePersianDatetimePicker from "vue-persian-datetime-picker";
import Table from "@/components/Table";
import MessageButton from "@/components/MessageButton";
import DeleteButton from "@/components/DeleteButton";
import NewMessage from "@/components/NewMessage";
import Pagination from "@/components/Pagination";
import { fetchItems, deletePayment } from "@Newfiling/module-payment/src/api";
import moment from "jalali-moment";
import { addCommas } from "@persian-tools/persian-tools";
import { getDataFromUrl, updateUrlQueries } from "@Newfiling/Services";

export default {
  name: "DesktopLogin",

  components: {
    Table,
    Pagination,
    NewMessage,
    MessageButton,
    datePicker: VuePersianDatetimePicker,
    DeleteButton,
  },

  data() {
    return {
      filters: {
        modal: false,
        Userfilter: null,
        DateSelection1: null,
        DateSelection2: moment().format("jYYYY/jMM/jDD"),
        skip: 0,
        sortparameter: 0,
        take: 10,
      },

      table: {
        headers: [
          "#",
          "نام مدیر",
          "موبایل مدیر",
          "نام آژانس",
          "موبایل پرداخت کننده",
          "نوع کاربر",
          "موضوع",
          "شماره پیگیری",
          "وضعیت",
          "مبلغ(تومان)",
          "تاریخ",
          "عملیات",
        ],
        items: [],
        totalCount: 0,
        loading: false,
      },

      deleteItem: {
        modal: false,
        loading: false,
        id: null,
      },

      viewMessage: {
        modal: false,
        body: "",
      },

      newMessage: {
        modal: false,
        phoneNumber: null,
      },
    };
  },

  beforeMount() {
    this.$store.commit("Breadcrumb/setBreadcrumb", [
      {
        text: "پنل مدیریت نیوفایل",
        disabled: false,
        href: "/panel/dashboard",
      },
      {
        text: "لیست تراکنش ها",
        disabled: true,
        href: "/panel/payments/list",
      },
    ]);
  },

  mounted() {
    this.$nextTick(() => {
      this.filters = {
        ...this.filters,
        ...getDataFromUrl(this.$route),
      };
      this.fetchItems();
    });
  },

  methods: {
    async destroyItem() {
      this.deleteItem.loading = true;
      try {
        await deletePayment(this.deleteItem.id);
        this.$toast.success("پرداخت مورد نظر با موفقیت حذف شد.");
        this.deleteItem = {
          ...this.deleteItem,
          modal: false,
          loading: false,
          id: null,
        };
        await this.fetchItems();
      } catch (e) {
        console.log(e);
      }
      this.deleteItem.loading = false;
    },

    async fetchItems(filters = {}) {
      this.filters = {
        ...this.filters,
        ...filters,
      };

      this.filters.modal = false;
      this.table.loading = true;

      updateUrlQueries(this.$route.path, this.filters);

      try {
        const res = (
          await fetchItems({
            ...this.filters,
            skip: this.filters.skip * this.filters.take,
          })
        )?.data || {
          message: [],
          recordcount: 0,
        };
        this.table.items = res?.message.map((item) => {
          return {
            username: item.managername,
            managermobile: item.managermobile,
            agencyname: item.agencyname,
            customermobile: item.customermobile,
            customername: item.customername,
            subject: item.subscriptionname,
            refId: item.refId,
            AccountType: item.AccountType,
            status: Boolean(item.statuspayment),
            price: addCommas(item.price),
            created_at: item.solarTransactiontime,
            actions: {
              replay: () => {
                this.newMessage = {
                  modal: true,
                  phoneNumber: item.customermobile.substring(1),
                };
              },
              destroy: () => {
                this.deleteItem.modal = true;
                this.deleteItem.id = item.id;
              },
            },
          };
        });
        this.table.totalCount = res?.recordcount;
      } catch (e) {
        console.log(e);
      }
      this.table.loading = false;
    },

    resetFilters() {
      this.filters = {
        ...this.filters,
        modal: false,
        Userfilter: null,
        DateSelection1: null,
        DateSelection2: moment().format("jYYYY/jMM/jDD"),
        skip: 0,
        sortparameter: 0,
        take: 10,
      };
      this.fetchItems();
    },
  },
};
</script>
